

export function convertToYear(year) {
    if (year >= 0) {
        return `${year} AD`
    } else {
        return `${year * -1} BC`
    }
}

export const ThreadsEnum = {
    general: "general",
	nation: "nation",
	bloodline: "bloodline",
	prophecy: "prophecy",
	character: "character",
    
}

export const ThreadTypes = Object.values(ThreadsEnum);

import React from 'react'
import NavBar from '../components/NavBar'

import { todos } from '../data.js';
import { Link } from 'react-router-dom';
import image from '../example-image.png'; // Tell webpack this JS file uses this image
import background from '../background.svg'; // Tell webpack this JS file uses this image

// Styling
import './Home.css';

// Components
import ContactForm from '../components/ContactForm.js';
import Footer from '../components/Footer.js';

const Home = () => {
  return (
    <>
        <NavBar />

        <div className='section-1'>
          <img className='banner' src={image} alt="Example img of the tool being used." />
          <div className='section-1a'>
            <h1>Stuck on biblical timelines?</h1>
            <body>We can help with that. We provide the tools you need to become biblically literate. <br /><br />Don’t believe us? </body>
            <br />
            <Link to='/history' className='button-link'>
              <button className='button-text' >DEMO</button >
            </Link>
          </div>
        </div>

        <div className='who-we-are' >
          <img src={background} alt='background' style={{ position: 'absolute', zIndex: -1, width: '100vw', height: '120vh', maxHeight: '800px', objectFit: 'cover', overflow: 'visible' }}/>
          <h0 className='h0'> MADE BY <span className='span-highlight'>STUDENTS</span> <br /> FOR <span className='span-highlight'>STUDENTS</span> </h0>
          <div id='who-we-are' className='who-we-are-blurb'>
            <h1 className='span-text'>WHO WE ARE</h1>
            <body className='span-text'>We’re a San Diego based company working to provide real, easy-to-understand and palatable historical insights to real biblical timelines.
                <br />
                <br />
                We live in the most technologically advanced time and place in history - and remain the most biblically illiterate generation ever to exist. We plan to change that by offering modern study tools.
              </body>
              <br />
              <div style={{ display: 'flex', maxWidth: '340px !important', justifyContent: 'space-between'}}>
                <h4 className='span-tags'>USER-FRIENDLY</h4>
                <h4 className='span-tags'>SHAREABLE</h4>
                <h4 className='span-tags'>EDUCATIONAL</h4>
              </div>  
          </div>
        </div>


        <div className='section-full'>
          <div className='section-full-inner'>
            <h1 className='wrap'>DO YOU BELIEVE IN WHAT WE'RE DOING?</h1>
            <body>Help us fund our mission by donating or spreading the word.</body>
            <br />
            <div className='flex-row'>
              <Link to='/donate' className='button-link'>
                <button className='button-text' >DONATE</button >
              </Link>
              <Link to='/share' className='button-link-outlined'>
                <button className='button-text-outlined' >SHARE</button >
              </Link>
            </div>
          </div>
        </div>

        <div className='section-full'>
          <div className='section-full-inner'>
            <h1>QUESTIONS?</h1>
            <body>Send us an email! We would love to hear from you.</body>
            <br />
            <div>
              <ContactForm />
            </div>
          </div>
        </div>

        <Footer />



        {/* <h1>Home Page</h1> */}

        {/* <ul>
          {todos.map((x) => (
            <Link to={x.slug} id={x.id} >{x.todo}</Link>
          ))}
        </ul> */}
    </>
  )
}

export default Home

export const theologyBooksTimeline = [
    {
        threadName: 'The Bondage of the Will - Martin Luther',
        type: 'book',
        beginning: '1525',
        destruction: '1525',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [
            {
                threadName: 'Lutheran edition',
                type: 'translation',
                children: [],
            },
        ],
    },
    {
        threadName: 'Institutes of the Christian Religion - John Calvin',
        type: 'book',
        beginning: '1536', // either publication date or est. date
        destruction: '1536', // either end of printing or destruction
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [
            {
                threadName: 'French edition',
                type: 'translation',
                children: [],
            },
        ],
    },
    {
        threadName: 'The Heidelberg Catechism',
        type: 'book',
        beginning: '1563',
        destruction: '1563',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [
            {
                threadName: 'German edition',
                type: 'translation',
                children: [],
            },
        ],
    },
    {
        threadName: 'The Westminster Confession of Faith',
        type: 'book',
        beginning: '1646',
        destruction: '1646',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [
            {
                threadName: 'Shorter Catechism',
                type: 'subsection',
                children: [],
            },
        ],
    },
];


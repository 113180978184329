import logo from '../logo.svg';
import '../App.css';

import { MapInteractionCSS } from 'react-map-interaction';
import React, { useState, useEffect } from 'react';
import * as Timelines from '../data.js';
// import { booksChronologicalOrder } from '../booksChronologicalOrder.js';


// Components
import RadioButton from '../components/RadioButton.js';
import EventThread from '../components/EventThread.js';
import NavButton from '../components/NavButton.js';

// Services
import { convertToYear, ThreadTypes, ThreadsEnum } from "../services.js"; 
import Timeline from '../components/Timeline.js';

const DisplayModeEnum = {
	default: "default mode",
	timeline: "timeline mode",
}

function GridThread({ tabSelected, i, thread, displayMode }) {
  console.log('tree: ', thread);
  // console.log('david : ');
  // console.log(davidThread[0].length());

  return (
      thread.tree.map((item, index) => ( // Create event object
        <div key={`${i}, ${index}, ${item.threadName}, ${convertToYear(item.beginning)}`} 
        style={{ 
          backgroundColor: '#141132',
          textAlign: 'center',
          fontSize: '30px',
          padding: '0.5rem',
          borderRadius: '8px',
          gridColumn: `${item.beginning} / ${item.destruction}`,
          gridRow: `${i+1}`,
          gridAutoColumns: 'max-content',
          display: 'grid',
        }}>
          <h2>
            {item.threadName}
          </h2>
          <h3>
            {convertToYear(item.beginning)}
          </h3>
      </div>
    ))
)}

function History() {
  const [tabSelected, setTabSelected] = useState('threads');
  const [activeThreads, setActiveThreads] = useState([]);     // Threads on the board
  const [threads, setThreads] = useState([]);                 // Threads on the sidebar
  const [displayMode, setDisplayMode] = useState(DisplayModeEnum.default);
  const [boardWidth, setBoardWidth] = useState(300);
  const [boardHeight, setBoardHeight] = useState(800);
  const [startingYear, setStartingYear] = useState(0);
  const [gridSize, setGridSize] = useState(5);
  
  useEffect(() => {
    onMount();
  }, []);

  function onMount() {
    addThread('The Life of King David', ThreadsEnum.character, Timelines.davidTimeline);
    addThread('The Life of Jesus', ThreadsEnum.character, Timelines.jesusTimeline);
    addThread('The Life of John The Baptist', ThreadsEnum.character, Timelines.johnTheBaptistTimeline);
    addThread('The Life of Paul', ThreadsEnum.character, Timelines.paulTimeline);
    addThread('Books of the Bible', ThreadsEnum.general, Timelines.inspiredBooksTimeline);
    addThread('Main Events', ThreadsEnum.general, Timelines.theologyBooksTimeline);
  }

  function onTest() {
    console.log('this is a test button!');
  }

  function addThread(name, type, thread) { // This adds to the sidebar, not the board.
    console.log('adding thread to sidebar...');

    const newThread = {
      name: name,
      type: type,
      tree: thread,
      smallestSegment: '10',
      start: 0,
      end: 0,
      isActive: false,
    };

    let lowestVal = newThread.tree[1].beginning - newThread.tree[0].beginning;
    for (let i = 0, l = newThread.tree.length; i < l - 1; i++) {
      const dif = newThread.tree[i+1].beginning - newThread.tree[i].beginning;
      if (lowestVal < dif) {
        lowestVal = dif;
      }
    }

    // Find grid marks...
    newThread.smallestSegment = lowestVal;
    setStartingYear(newThread.tree[0].beginning);
    newThread.start = thread[0].beginning;
    newThread.end = thread[newThread.tree.length - 1].destruction;
    
    setGridSize(lowestVal);

    console.log('starting year: ', newThread.tree[0].beginning);
    console.log('closest: ', lowestVal);

    setThreads(oldArray => [...oldArray, newThread]);
  }


  function onRadioChange(event, item) { // This adds to the board, not the sidebar
    // Copy the old array
    let updatedThreads = [...threads]; 

    // Find index of this item
    const itemIndex = threads.findIndex((element) => element.name === item.name);
    console.log("item index: ", itemIndex)

    // Change item at index
    updatedThreads[itemIndex].isActive = !item.isActive; 

    // Make the change
    setThreads(threads);

    // Add or remove this to/from active threads 
    if (item.isActive) {
      addActiveThread(threads[itemIndex])
    } else {
      removeActiveThread(threads[itemIndex].name);
    }
  }

  function addActiveThread(thread) { // This adds to the board, not the sidebar - THIS IS A TEMP TESTING FEATURE AS WELL
    console.log('adding thread to board: ', thread)

    // addThread('King Josiah', ThreadsEnum.bloodline);
    setActiveThreads(oldArray => [...oldArray, [thread]]);
  }


  function removeActiveThread(name) { // This adds to the board, not the sidebar - THIS IS A TEMP TESTING FEATURE AS WELL
    console.log('remove thread from board: ', name);
    setActiveThreads(list => list.filter(item => item[0].name !== name));
  }


  function addPoint(name, time) {
    const newThread = {
      threadName: name,
      type: 'character',
      beginning: convertToYear(time), // either birth or est. date
      destruction: '1420 BC', // either death or destruction
      children: [ // These are subsequent threads that may be added based on filters. For example King David may have first/second/third generations added
          {
            threadName: 'Amnon',
            type: 'bloodline',
            children: [],
          }
      ],
    };
    setActiveThreads(oldArray => [...oldArray, [newThread]]);
}
    
  return (
    <div className="App">
      <div className="transition-all borders navbar" />
      <div className='root-content'>
        <div className="transition-all sidebar">
          <div className='sidebar-nav disabled'>
            <NavButton text='filters' selected={tabSelected} onChange={(val) => {setTabSelected(val)}} />
            <NavButton text='threads' selected={tabSelected} onChange={(val) => {setTabSelected(val)}} />
          </div>
          {/* <button onClick={(val) => {onTest()}} > Test Button</button> */}
          {ThreadTypes.map((type, index) => (
            <div>
            <h4 className="disabled" key={`${index}`} index={index}> 
              {type}
            </h4>
            {threads.map((item, index) => (
              item.type === type && 
                <RadioButton itemName={item.name} key={`${index}`} index={index} checked={item.isActive} onChange={(e) => {onRadioChange(e, item)}}/>
            ))}
            </div>
          ))}
        </div>
        <div className="content" >
          {tabSelected === 'threads' ? // This is the default board
          <MapInteractionCSS
            showControls
            defaultValue={{
              scale: 1,
              translation: { x: 20, y: 300 }
            }}
            minScale={0.25}
            maxScale={2}
            translationBounds={{
              xMax: boardWidth,
              yMax: boardHeight,
            }}
          >
            {activeThreads.map((item, index) => (
              <EventThread key={`${index}`} tabSelected={tabSelected} threadName={item.name} index={index} thread={item[0]} displayMode={displayMode} startingYear={startingYear} gridSize={gridSize}/>
            ))}
          </MapInteractionCSS> :           <MapInteractionCSS
            showControls
            defaultValue={{
              scale: 1,
              translation: { x: 20, y: 300 }
            }}
            minScale={0.25}
            maxScale={2}
            translationBounds={{
              xMax: boardWidth,
              yMax: boardHeight,
            }}
          >  {/*This is the second board*/}
            {
              <div style={{
                display: 'grid',
                gap: '1rem',
                rowGap: '1rem',
                backgroundColor: 'transparent',
                padding: '00px',
                transition: 'opacity 0.2s ease-in 0s, fill 0.2s ease-in 0s'
              }}>
                {activeThreads.map((item, index) => (
                  <>
                    <GridThread key={`${index}`} tabSelected={tabSelected} threadName={item.name} i={index} thread={item[0]} displayMode={displayMode} startingYear={startingYear} gridSize={gridSize}/>
                    <span style={{ display: 'grid', backgroundColor: '#141132', gridRow: index + 1, marginRight: '-2px', alignSelf: 'center', gridColumn: `${item[0].start} / ${item[0].end}`, height: '3px'}} />
                  </>
                ))}
              </div>
            }
          </MapInteractionCSS>}
        </div>
      </div>
    </div>
  );
}


export default History;
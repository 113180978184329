import React, { useState, useEffect } from 'react';

// Styling

import './ContactForm.css';

export default class ContactForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {name: '', email: '', emailBody: ''};
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleChange(event, type) {
        if (type === 'name') {
            this.setState({name: event.target.value});
        } else if (type === 'email') {
            this.setState({email: event.target.value});
        } else if (type === 'email body') {
            this.setState({emailBody: event.target.value});
        }
      }

    handleSubmit(event) {
    //   alert('A name was submitted: ' + this.state.name + ', ' + this.state.email + ', ' + this.state.emailBody);
      event.preventDefault();
    }
  
    render() {
      return (
        <form onSubmit={this.handleSubmit}>
            <div style={{ display: 'flex', width: '100%' }}>
                <input className='name' type="text" placeholder='Full Name' value={this.state.name} onChange={(e) => this.handleChange(e, 'name')} />
                <input className='email' type="email" placeholder='Email' value={this.state.email} onChange={(e) => this.handleChange(e, 'email')} />
            </div>
            <textarea cols='3' rows='4' className='email-body' type="text" placeholder='Message body...' value={this.state.emailBody} onChange={(e) => this.handleChange(e, 'email body')} />
            <input className='submit' type="submit" value="SEND EMAIL" />
        </form>
      );
    }
  }
export const johnTheBaptistTimeline = [
    {
        threadName: 'Birth of John the Baptist',
        type: 'event',
        beginning: '-5', // approximate year of birth
        destruction: '-5',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
    {
        threadName: 'Circumcision of John the Baptist',
        type: 'event',
        beginning: '-5', // eight days after birth
        destruction: '-5',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
    {
        threadName: 'Ministry of John the Baptist',
        type: 'event',
        beginning: '29', // approximate start of ministry
        destruction: '29',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
    {
        threadName: 'Baptism of Jesus by John',
        type: 'event',
        beginning: '29', // around the start of John's ministry
        destruction: '29',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
    {
        threadName: 'Imprisonment of John the Baptist',
        type: 'event',
        beginning: '31', // near the end of John's ministry
        destruction: '31',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
    {
        threadName: 'Death of John the Baptist',
        type: 'event',
        beginning: '32', // approximate year of his execution
        destruction: '32',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
];

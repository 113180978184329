import { convertToYear } from "../services"; 


export default function EventThread({ tabSelected, i, thread, displayMode }) {
    // console.log('tree: ', thread);
    // console.log('tree length: ', thread.length);
  
    const startingYear=0
    const gridSize=5
  
    console.log("this is they starting margin: ", `${(thread.tree[0].beginning - startingYear) * gridSize}px`);
  
    return (
      <div className={tabSelected === 'threads' ? 'style1' : 'style2'}>
        <h3 className='thread-title'>
          {thread.name}
        </h3>
        {tabSelected === 'threads' ? thread.tree.map((item, index) => ( // Create event object
          <>
            <div className="item" key={`${i}, ${index}, ${item.threadName}, ${convertToYear(item.beginning)}`} style={{ marginLeft: index === -1 && (`${(item.beginning - startingYear) * gridSize}px`)}}>
              <h2>
                {item.threadName}
              </h2>
              <h3>
                {convertToYear(item.beginning)}
              </h3>
            </div>
            <span className="timeline-line" style={{ display: index >= thread.tree.length - 1 ? 'none' : '', width: (index > thread.tree.length - 1 ? `${gridSize * (thread.tree[index+1].beginning - item.beginning)}px` : '32px')}} />
          </>
        ))
      : thread.tree.map((item, index) => (
        <div className="item" key={`${index}, ${i}, ${item.threadName}, ${convertToYear(item.beginning)}`} 
          style={{
            position: 'absolute',
            left: `${(item.beginning) * gridSize}px`,
          }}> 
          <h2>
            {item.threadName}
          </h2>
          <h3>
            {convertToYear(item.beginning)}
          </h3>
        </div>
        ))}
      </div>
    );
  }
  

export const inspiredBooksTimeline = [
    {
        threadName: 'Job',
        type: 'book',
        beginning: '-1500', // possibly the oldest book in the Bible
        destruction: '-1500',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Genesis',
        type: 'book',
        beginning: '-1445', // likely written during Moses' time
        destruction: '-1445',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Exodus',
        type: 'book',
        beginning: '-1445',
        destruction: '-1445',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Leviticus',
        type: 'book',
        beginning: '-1445',
        destruction: '-1445',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Numbers',
        type: 'book',
        beginning: '-1445',
        destruction: '-1445',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Deuteronomy',
        type: 'book',
        beginning: '-1406', // likely written towards the end of Moses' life
        destruction: '-1406',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Joshua',
        type: 'book',
        beginning: '-1405',
        destruction: '-1405',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Judges',
        type: 'book',
        beginning: '-1375', // written during the time of the Judges
        destruction: '-1375',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Ruth',
        type: 'book',
        beginning: '-1150', // likely written during the time of the Judges
        destruction: '-1150',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: '1 Samuel',
        type: 'book',
        beginning: '-1050', // written during Samuel's lifetime
        destruction: '-1050',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: '2 Samuel',
        type: 'book',
        beginning: '-1010', // written after King David's time
        destruction: '-1010',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: '1 Kings',
        type: 'book',
        beginning: '-971', // written during Solomon's reign
        destruction: '-971',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: '2 Kings',
        type: 'book',
        beginning: '-561', // written after the Babylonian exile
        destruction: '-561',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: '1 Chronicles',
        type: 'book',
        beginning: '-1010', // compiled after King David's time
        destruction: '-1010',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: '2 Chronicles',
        type: 'book',
        beginning: '-450', // written post-Babylonian exile
        destruction: '-450',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Ezra',
        type: 'book',
        beginning: '-460', // likely written during the post-exilic period
        destruction: '-460',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Nehemiah',
        type: 'book',
        beginning: '-445', // written in the post-exilic period
        destruction: '-445',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Esther',
        type: 'book',
        beginning: '-460', // compiled during the Persian period
        destruction: '-460',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Job',
        type: 'book',
        beginning: '-1500', // written during Moses' time
        destruction: '-1500',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Psalms',
        type: 'book',
        beginning: '-1000', // written over a long period, from David to Solomon's reign
        destruction: '-1000',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Proverbs',
        type: 'book',
        beginning: '-971', // written during Solomon's reign
        destruction: '-971',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Ecclesiastes',
        type: 'book',
        beginning: '-931', // likely written by Solomon later in his reign
        destruction: '-931',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Song of Songs',
        type: 'book',
        beginning: '-931', // written during Solomon's reign
        destruction: '-931',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Isaiah',
        type: 'book',
        beginning: '-700', // written during Isaiah's time
        destruction: '-700',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Jeremiah',
        type: 'book',
        beginning: '-626', // written during Jeremiah's time
        destruction: '-626',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Lamentations',
        type: 'book',
        beginning: '-586', // possibly written by Jeremiah after Jerusalem's destruction
        destruction: '-586',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Ezekiel',
        type: 'book',
        beginning: '-593', // written during Ezekiel's exile in Babylon
        destruction: '-593',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Daniel',
        type: 'book',
        beginning: '-536', // written during Daniel's exile in Babylon
        destruction: '-536',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Hosea',
        type: 'book',
        beginning: '-755', // likely written during Hosea's time
        destruction: '-755',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Joel',
        type: 'book',
        beginning: '-835', // likely written in Judah's early history
        destruction: '-835',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Amos',
        type: 'book',
        beginning: '-760', // written during Amos' prophetic mission
        destruction: '-760',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Obadiah',
        type: 'book',
        beginning: '-586', // written during : after Jerusalem's destruction
        destruction: '-586',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Jonah',
        type: 'book',
        beginning: '-785', // likely written after Jonah's mission to Nineveh
        destruction: '-785',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Micah',
        type: 'book',
        beginning: '-740', // written during Micah's time
        destruction: '-740',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Nahum',
        type: 'book',
        beginning: '-630', // likely written during the Neo-Assyrian period
        destruction: '-630',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Habakkuk',
        type: 'book',
        beginning: '-609', // written during the Babylonian conquest of Judah
        destruction: '-609',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Zephaniah',
        type: 'book',
        beginning: '-640', // likely written during the reign of King Josiah
        destruction: '-640',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Haggai',
        type: 'book',
        beginning: '-520', // written after the Babylonian exile
        destruction: '-520',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Zechariah',
        type: 'book',
        beginning: '-520', // written after the Babylonian exile
        destruction: '-520',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Malachi',
        type: 'book',
        beginning: '-430', // the last book of the Old Testament
        destruction: '-430',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Matthew',
        type: 'book',
        beginning: '60', // the first book of the New Testament
        destruction: '60',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Mark',
        type: 'book',
        beginning: '55', // likely the first Gospel written
        destruction: '55',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Luke',
        type: 'book',
        beginning: '60', // the third Gospel
        destruction: '60',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'John',
        type: 'book',
        beginning: '85', // the last Gospel
        destruction: '85',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Acts',
        type: 'book',
        beginning: '62', // written by Luke as a continuation of the Gospel
        destruction: '62',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Romans',
        type: 'book',
        beginning: '57', // one of Paul's letters
        destruction: '57',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: '1 Corinthians',
        type: 'book',
        beginning: '55', // one of Paul's letters
        destruction: '55',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: '2 Corinthians',
        type: 'book',
        beginning: '56', // one of Paul's letters
        destruction: '56',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Galatians',
        type: 'book',
        beginning: '48', // one of Paul's earliest letters
        destruction: '48',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Ephesians',
        type: 'book',
        beginning: '62', // one of Paul's prison epistles
        destruction: '62',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Philippians',
        type: 'book',
        beginning: '62', // one of Paul's prison epistles
        destruction: '62',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Colossians',
        type: 'book',
        beginning: '62', // one of Paul's prison epistles
        destruction: '62',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: '1 Thessalonians',
        type: 'book',
        beginning: '50', // one of Paul's earliest letters
        destruction: '50',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: '2 Thessalonians',
        type: 'book',
        beginning: '51', // one of Paul's early letters
        destruction: '51',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: '1 Timothy',
        type: 'book',
        beginning: '62', // written by Paul to Timothy
        destruction: '62',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: '2 Timothy',
        type: 'book',
        beginning: '64', // written by Paul before his martyrdom
        destruction: '64',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Titus',
        type: 'book',
        beginning: '63', // written by Paul to Titus
        destruction: '63',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Philemon',
        type: 'book',
        beginning: '62', // one of Paul's prison epistles
        destruction: '62',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Hebrews',
        type: 'book',
        beginning: '68', // likely written during the Jewish-Roman wars
        destruction: '68',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'James',
        type: 'book',
        beginning: '45', // one of the earliest New Testament books
        destruction: '45',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: '1 Peter',
        type: 'book',
        beginning: '62', // written during the reign of Nero
        destruction: '62',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: '2 Peter',
        type: 'book',
        beginning: '65', // written shortly before Peter's martyrdom
        destruction: '65',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: '1 John',
        type: 'book',
        beginning: '85', // written by the Apostle John
        destruction: '85',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: '2 John',
        type: 'book',
        beginning: '85', // written by the Apostle John
        destruction: '85',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: '3 John',
        type: 'book',
        beginning: '85', // written by the Apostle John
        destruction: '85',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Jude',
        type: 'book',
        beginning: '68', // possibly written by Jude, Jesus' brother
        destruction: '68',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Revelation',
        type: 'book',
        beginning: '95', // likely written by John during his exile on Patmos
        destruction: '95',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
];

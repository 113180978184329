export const jesusTimeline = [
    {
        threadName: 'Birth of Jesus',
        type: 'event',
        beginning: '-4', // approximate year of Jesus' birth
        destruction: '-4',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Visit of the Magi',
        type: 'event',
        beginning: '-2', // approximate year of the Magi's visit
        destruction: '-2',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Presentation at the Temple',
        type: 'event',
        beginning: '-4', // occurred when Jesus was about 40 days old
        destruction: '-4',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Flight to Egypt',
        type: 'event',
        beginning: '-3', // possibly shortly after the Magi's visit
        destruction: '-3',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Return to Nazareth',
        type: 'event',
        beginning: '-1', // approximate year of return to Nazareth
        destruction: '-1',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Jesus at the Temple (age 12)',
        type: 'event',
        beginning: '12', // when Jesus was 12 years old
        destruction: '12',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Baptism of Jesus',
        type: 'event',
        beginning: '29', // when Jesus was baptized by John the Baptist
        destruction: '29',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Temptation in the Wilderness',
        type: 'event',
        beginning: '29', // shortly after the baptism
        destruction: '29',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Calling of the First Disciples',
        type: 'event',
        beginning: '29', // around the beginning of Jesus' ministry
        destruction: '29',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Sermon on the Mount',
        type: 'event',
        beginning: '30', // possibly during the first year of ministry
        destruction: '30',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Transfiguration of Jesus',
        type: 'event',
        beginning: '32', // towards the end of Jesus' ministry
        destruction: '32',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Triumphal Entry into Jerusalem',
        type: 'event',
        beginning: '33', // beginning of the last week of Jesus' life
        destruction: '33',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Last Supper',
        type: 'event',
        beginning: '33', // the night before Jesus' crucifixion
        destruction: '33',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Crucifixion',
        type: 'event',
        beginning: '33', // the day after the Last Supper
        destruction: '33',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Resurrection',
        type: 'event',
        beginning: '33', // three days after the Crucifixion
        destruction: '33',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
    {
        threadName: 'Ascension',
        type: 'event',
        beginning: '33', // forty days after the Resurrection
        destruction: '33',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [],
    },
];

import React, { useState, useEffect } from 'react';

// Styling

import './Footer.css';

import { Link } from 'react-router-dom';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';

const iconSX = {
  fontSize: '32px',
  // "&:hover": {
  //   color: '#FFFFFF',
  //   cursor: 'pointer',
  // },
};

export default function Footer(props) {


  return (
    <div className='container'>
      <div className='footer-link-section'>
        <div className='footer-column'>
          <h2>CONNECT</h2>
          <Link className='footer-link' to=''>Subscribe</Link>
          <Link className='footer-link' to=''>Collaborate</Link>
          <div className='footer-row'>
            <Link className='footer-icon' to=''><FacebookOutlinedIcon sx={iconSX} /></Link>
            <Link className='footer-icon' to=''><InstagramIcon sx={iconSX} /></Link>
            <Link className='footer-icon' to=''><XIcon sx={iconSX} /></Link>
          </div>
        </div>
        <div className='footer-column'>
          <h2>LINKS</h2>
          <Link className='footer-link' to=''>Give</Link>
          <Link className='footer-link' to=''>Biblical Literacy Tools</Link>
          <Link className='footer-link' to=''>Preaching Tools</Link>
        </div>
      </div>
      <h2>THE BIBLE TIMELINE</h2>
      <body className='footer-rights'>© 2024 the bible timeline</body>
    </div>
  );
}
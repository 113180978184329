export const paulTimeline = [
    {
        threadName: 'Birth of Saul',
        type: 'event',
        beginning: '5', // approximate year of birth
        destruction: '5',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
    {
        threadName: 'Saul Studies under Gamaliel',
        type: 'event',
        beginning: '25', // Saul's religious training
        destruction: '25',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
    {
        threadName: 'Saul Persecutes Christians',
        type: 'event',
        beginning: '35', // Saul's persecution of early Christians
        destruction: '35',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
    {
        threadName: 'Conversion on the Road to Damascus',
        type: 'event',
        beginning: '36', // Saul's encounter with Jesus
        destruction: '36',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
    // {
    //     threadName: 'Baptism by Ananias',
    //     type: 'event',
    //     beginning: '36', // following Saul's conversion
    //     destruction: '36',
    //     length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
    //     children: [],
    // },
    {
        threadName: "Paul Begins His Ministry",
        type: 'event',
        beginning: '37', // Paul begins his ministry after conversion
        destruction: '37',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
    {
        threadName: 'First Missionary Journey',
        type: 'event',
        beginning: '46', // Paul's first missionary journey
        destruction: '46',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
    {
        threadName: 'Council of Jerusalem',
        type: 'event',
        beginning: '49', // Paul's involvement in the Council of Jerusalem
        destruction: '49',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
    {
        threadName: 'Second Missionary Journey',
        type: 'event',
        beginning: '50', // Paul's second missionary journey
        destruction: '50',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
    {
        threadName: 'Third Missionary Journey',
        type: 'event',
        beginning: '54', // Paul's third missionary journey
        destruction: '54',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
    {
        threadName: "Paul's Arrest in Jerusalem",
        type: 'event',
        beginning: '57', // Paul's arrest leading to his imprisonment
        destruction: '57',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
    {
        threadName: "Paul's Journey to Rome",
        type: 'event',
        beginning: '60', // Paul's journey to Rome under guard
        destruction: '60',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
    {
        threadName: "Paul's First Roman Imprisonment",
        type: 'event',
        beginning: '61', // Paul's imprisonment in Rome
        destruction: '61',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
    {
        threadName: "Paul's Second Roman Imprisonment",
        type: 'event',
        beginning: '66', // Paul's second imprisonment in Rome
        destruction: '66',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
    {
        threadName: "Paul's Martyrdom",
        type: 'event',
        beginning: '67', // approximate year of Paul's execution
        destruction: '67',
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction); },
        children: [],
    },
];

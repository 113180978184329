
export default function NavButton({ text, selected, onChange }) {
    return (
      <div className={`transition-all sidebar-header-button ${selected === text ? 'selected-tab' : ''}`} onClick={() => {onChange(text)}}>
        <h1>
          {text}
        </h1>
        <div className={`${selected === text ? 'selected-tab-underline' : ''}`}/>
      </div>
    );
  }
  
import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

// Styling
import './NavBar.css';

// Components
// import MenuModal from './MenuModal.js';


// Other
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';

// Icon
import { Menu } from '@mui/icons-material';

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });
  
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }
  
  HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

class MenuModal extends React.Component {
  
    render() {
      return (
        <div className='menu'>
            <ul>
                <li>Bible Timeline</li>
                <li>Bible Trivia</li>
                <li>Preaching Assistant</li>
            </ul>
        </div>
      );
    }
  }
  
export default function NavBar(props) {
    const [menuStatus, setMenuStatus] = useState(false);


    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    
    useEffect(() => {    
        function handleResize() {
        setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    
    }, []);
    

    return (
      <React.Fragment>
        <CssBaseline />
        <HideOnScroll {...props}>
            <AppBar style={{ background: '#FBFBFD', height: '70px' }}>
            <Toolbar>
                <Link className='remove-margin' to='/'><h1>THE BIBLE TIMELINE</h1></Link>
                {window.innerWidth > 750 ? <div className='nav-wrapper'>
                    <Link to='/#who-we-are'>WHO WE ARE</Link>
                    <Link to='/#products' onClick={ () => setMenuStatus(!menuStatus)}>PRODUCTS</Link>
                    <div className={`menu ${menuStatus ? 'menu-on' : 'menu-off'}`}>
                        <ul>
                            
                            <Link className='modal' to='/history'>
                                <li>Bible Timeline</li>
                            </Link>
                            <li>Bible Trivia</li>
                            <li>Preaching Assistant</li>
                            <li>Coming Soon</li>
                        </ul>
                    </div>
                    <Link to='/give'>GIVE</Link>
                    <Link to='/contact'>CONTACT</Link>
                </div> : 
                <div className='nav-wrapper'><Menu style={{ color: '#151232', }}/></div>
                }
            </Toolbar>
            </AppBar>
        </HideOnScroll>
        <Toolbar />
        <Container>
          <Box sx={{ my: 2 }}>
            {/* Content */}
          </Box>
        </Container>
      </React.Fragment>
    );
  }

// <div>
    // <Link to='/'>Home</Link>
    // <Link to='/history'>Timeline</Link>
// </div>
// Radio buttons
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import Circle from '@mui/icons-material/Circle';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';

const label = { inputProps: { 'aria-label': 'Thread type' } };

export default function RadioButton ({ itemName, index, checked, onChange }) {
    return (
      <div className='radio'>
        <Checkbox
          {...label}
          sx={{
            color: '#ACACAC',
            '&.Mui-checked': {
              color: '#8C8C8C',
            },
          }}
          checked={checked}
          onChange={(index) => onChange(index)}
          icon={<CircleOutlinedIcon style={{ colors: '00000010' }}/>}
          checkedIcon={<><CircleOutlinedIcon /><Circle style={{ position: 'fixed', color: '#151132', width: '14px' }}/></>}
        />
        <h5 className="disabled" key={`${index}.`} index={index}> 
          {itemName}
        </h5>
      </div>
    );
  }
  
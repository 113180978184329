export const davidTimeline = [
    {
        threadName: 'Birth of David',
        type: 'character',
        beginning: '-1040', // either birth or est. date
        destruction: '-1040', // either death or destruction
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [ // These are subsequent threads that may be added based on filters. For example King David may have first/second/third generations added
            {
                threadName: 'Amnon',
                type: 'bloodline',
                children: [],
            }
        ],
    },
    {
        threadName: "David's Coronation",
        type: 'character',
        beginning: '-1010', // either birth or est. date
        destruction: '-1010', // either death or destruction
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [ // These are subsequent threads that may be added based on filters. For example King David may have first/second/third generations added
            {
                threadName: 'Amnon',
                type: 'bloodline',
                children: [],
            }
        ],
    },
    {
        threadName: "David's Death",
        type: 'character',
        beginning: '-970', // either birth or est. date -980
        destruction: '-970', // either death or destruction
        length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
        children: [ // These are subsequent threads that may be added based on filters. For example King David may have first/second/third generations added
            {
                threadName: 'Amnon',
                type: 'bloodline',
                children: [],
            }
        ],
    },
  ]

// Router
import { createBrowserRouter } from 'react-router-dom';

// Pages
import History from './pages/History.js';
import Home from './pages/Home.js';
import Contact from './pages/Contact.js';
import ToDoItem from './pages/TodoItem.js';

export const router = createBrowserRouter([
  { path: '/', element: <Home /> },
  { path: '/history', element: <History /> },
  { path: '/contact', element: <Contact /> },
  { path: ':slug', element: <ToDoItem /> },
]);

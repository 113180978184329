export const todos = [
    { "id" : 1, "todo": "Walk the dog", "slug": "walk-the-dog"},
    { "id" : 2, "todo": "Wash the car", "slug": "wash-the-car"},
    { "id" : 3, "todo": "Grind Leetcode", "slug": "grind-leetcode"}
]


export const timelineFormat = {
    timelineName: 'Life of King David',
    type: 'character',
    start: '-1500', // Chronologically, the first event in this timeline
    end: '-1420', // Chronologically, the last event in this timeline
    length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
    microTimeline: [ // These are subsequent threads that may be added based on filters. For example King David may have first/second/third generations added
        {
                threadName: 'Amnon',
                type: 'bloodline',
                children: [],
        }
    ],
    thread: [],
};

export const threadFormat = {
    threadName: 'King David',
    type: 'bloodline' | 'character' | 'nation' | 'major event',
    beginning: '-1500', // either birth or est. date
    destruction: '-1420', // either death or destruction
    length: function () { return this.beginning - this.destruction === 0 ? 1 : Math.abs(this.beginning - this.destruction)},
    children: [ // These are subsequent threads that may be added based on filters. For example King David may have first/second/third generations added
        {
                threadName: 'Amnon',
                type: 'bloodline',
                children: [],
        }
    ],
};

// export * from './threads/bloodlines/someone.js';
// export * from './threads/characters/someone.js';
export * from './threads/characters/david.js';
export * from './threads/characters/jesus.js';
export * from './threads/characters/paul.js';
export * from './threads/characters/john-the-baptist.js';
export * from './threads/general/inspiredBooks.js';
export * from './threads/general/theologyBooks.js';

import React, { useState, useEffect } from 'react';

// Styling
import './App.css';

// Pages
import History from './pages/History.js';
import Home from './pages/Home.js';
import { RouterProvider } from 'react-router-dom';


// Assets
import logo from './logo.svg';

// Router
import { router } from './router.js';

// Todos
import { todos } from './data.js';
console.log(todos);



function App() {

  useEffect(() => {
    onMount();
  }, []);

  function onMount() { // This stuff happens on startup

  }

  function onTest() {
    console.log('this is a test button!');
  }

    
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}


export default App;